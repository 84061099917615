.hero {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/hero.png);
  background-size: cover;
  margin-top: 74px;
}

@media only screen and (max-width: 600px) {
  .hero {
      height: 50vh;
  }
}
