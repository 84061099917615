.button {
  height: 50px !important;
  border: solid 2px #841522 !important;
  background-color: white !important;
  font-size: 25px !important;
  color: black !important;
}

.socio,
.socio:hover,
.socio:active,
.socio:focus,
.socio:visited,
.socio:target {
  border: none !important;
  font-size: 34px !important;
  font-weight: bold !important;
  background: none !important;
  color: #000 !important;
  outline: none !important;
  outline-width: 0 !important;
}
