.asociates {
  display: flex;
  align-items: center;
}

.asociates-image {
  width: 50%;
}

.asociates-image img {
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {

  .card-title-container {
    justify-content: center;
  }

  .card-title-container h2 {
    margin-left: 0;
  }

  .asociates-image {
    display: none;
  }

  .socio {
    margin-left: 0 !important; 
    flex-direction: column;
  }

  .socio p {
    text-align: center;
    padding: 1em;
  }


}
