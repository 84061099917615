main {
  margin-top: -20em;
}

.practice-areas {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/practice-areas.png);
  background-size: cover;
  display: flex;
  align-items: center;
}


@media only screen and (max-width: 500px) {
  main {
    margin-top: -6em;
  }

  .practice-areas {
    height: auto;
    background-image: none;
  }

  .cards-section {
    padding: 1em !important;
  }

  .card {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 2em !important;
  }
  
  .card-title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card-title img {
    margin: 0 !important;
  }

  .social-media-section {
    flex-direction: column;
  }

  .social-media img {
    width: 100%;
  }

  .asesoria img {
    width: 85%;
  }

  .section-title {
    width: 100% !important;
    height: auto !important;
    margin-left: 0;
    padding: 0 !important;
    justify-content: center;
  }

  .section-title h2 {
    margin-left: 0;
    font-size: 50px !important;
  }

  .contacto {
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding:2em 1em !important;
  }

  .contacto article{
    margin: 0 !important;
  }

  .contacto article div{
    justify-content: center !important;
  }

  .br {
    display: none;
  }

  h2 {
    text-align: center;
  }
}
