@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h2 {
  font-size: 69px;
  margin-left: 16px;
  font-weight: normal;
  color: #1D2D4E;
}

p {
  font-size: 21px;
}

.hero {
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/hero.2881f8f4.png);
  background-size: cover;
  margin-top: 74px;
}

@media only screen and (max-width: 600px) {
  .hero {
      height: 50vh;
  }
}

.big-card-container {
  width: 95%;
}

.big-card__mini {
  display: flex;
  justify-content: flex-end;
}

.big-card__mini p {
  width: 500px;
  padding: 32px;
  margin: 0 32px;
  background-color: #1D2D4E;
  font-size: 20px;
  color: #fff;
}

.big-card {
  background-color: #fff;
  box-shadow: 17px 17px 17px rgba(0, 0, 0, 0.25);
  border-top-right-radius: 15px;
}

.card-title-container {
  display: flex;
  align-items: center;
  padding: 2em 0;
}

.our-firm-inside-card {
  display: flex;
  align-items: center;
  width: 95%;
  background-color: #cccccc;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.our-firm-inside-card div {
  height: 174px;
  margin: 0 32px;
  padding-left: 32px;
  border-left: solid 4px #841522;
  display: flex;
  align-items: center;
}

.card-mission {
  display: flex;
  justify-content: center;
}

.card-mission p {
  width: 950px;
  margin: 53px 0;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .card-title-container img {
    display: none;
  }

  .our-firm-inside-card {
     flex-direction: column;
     height: auto;
  }

  .our-firm-inside-card img {
     display: none;
  }

  .our-firm-inside-card div {
      height: auto;
      margin: 1em;
  }

  .card-mission {
      margin: 0 2em;
  }

}


@media only screen and (max-width: 600px) {
  .card-title-container img {
    display: none;
  }

  .our-firm-inside-card {
     flex-direction: column;
     height: auto;
  }

  .our-firm-inside-card img {
      width: 100%;
  }

  .our-firm-inside-card div {
      height: auto;
      margin: 1em;
  }

  .card-mission {
      margin: 0 2em;
  }
}

.asociates {
  display: flex;
  align-items: center;
}

.asociates-image {
  width: 50%;
}

.asociates-image img {
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {

  .card-title-container {
    justify-content: center;
  }

  .card-title-container h2 {
    margin-left: 0;
  }

  .asociates-image {
    display: none;
  }

  .socio {
    margin-left: 0 !important; 
    flex-direction: column;
  }

  .socio p {
    text-align: center;
    padding: 1em;
  }


}

.button {
  height: 50px !important;
  border: solid 2px #841522 !important;
  background-color: white !important;
  font-size: 25px !important;
  color: black !important;
}

.socio,
.socio:hover,
.socio:active,
.socio:focus,
.socio:visited,
.socio:target {
  border: none !important;
  font-size: 34px !important;
  font-weight: bold !important;
  background: none !important;
  color: #000 !important;
  outline: none !important;
  outline-width: 0 !important;
}

main {
  margin-top: -20em;
}

.practice-areas {
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/practice-areas.990766fb.png);
  background-size: cover;
  display: flex;
  align-items: center;
}


@media only screen and (max-width: 500px) {
  main {
    margin-top: -6em;
  }

  .practice-areas {
    height: auto;
    background-image: none;
  }

  .cards-section {
    padding: 1em !important;
  }

  .card {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 2em !important;
  }
  
  .card-title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card-title img {
    margin: 0 !important;
  }

  .social-media-section {
    flex-direction: column;
  }

  .social-media img {
    width: 100%;
  }

  .asesoria img {
    width: 85%;
  }

  .section-title {
    width: 100% !important;
    height: auto !important;
    margin-left: 0;
    padding: 0 !important;
    justify-content: center;
  }

  .section-title h2 {
    margin-left: 0;
    font-size: 50px !important;
  }

  .contacto {
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding:2em 1em !important;
  }

  .contacto article{
    margin: 0 !important;
  }

  .contacto article div{
    justify-content: center !important;
  }

  .br {
    display: none;
  }

  h2 {
    text-align: center;
  }
}

