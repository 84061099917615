.big-card-container {
  width: 95%;
}

.big-card__mini {
  display: flex;
  justify-content: flex-end;
}

.big-card__mini p {
  width: 500px;
  padding: 32px;
  margin: 0 32px;
  background-color: #1D2D4E;
  font-size: 20px;
  color: #fff;
}

.big-card {
  background-color: #fff;
  box-shadow: 17px 17px 17px rgba(0, 0, 0, 0.25);
  border-top-right-radius: 15px;
}

.card-title-container {
  display: flex;
  align-items: center;
  padding: 2em 0;
}

.our-firm-inside-card {
  display: flex;
  align-items: center;
  width: 95%;
  background-color: #cccccc;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.our-firm-inside-card div {
  height: 174px;
  margin: 0 32px;
  padding-left: 32px;
  border-left: solid 4px #841522;
  display: flex;
  align-items: center;
}

.card-mission {
  display: flex;
  justify-content: center;
}

.card-mission p {
  width: 950px;
  margin: 53px 0;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .card-title-container img {
    display: none;
  }

  .our-firm-inside-card {
     flex-direction: column;
     height: auto;
  }

  .our-firm-inside-card img {
     display: none;
  }

  .our-firm-inside-card div {
      height: auto;
      margin: 1em;
  }

  .card-mission {
      margin: 0 2em;
  }

}


@media only screen and (max-width: 600px) {
  .card-title-container img {
    display: none;
  }

  .our-firm-inside-card {
     flex-direction: column;
     height: auto;
  }

  .our-firm-inside-card img {
      width: 100%;
  }

  .our-firm-inside-card div {
      height: auto;
      margin: 1em;
  }

  .card-mission {
      margin: 0 2em;
  }
}
